import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import Container from "../components/UI/Container"
import Layout from "../components/UI/Layout"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import axios from 'axios';
import { API_URL } from "../constants/index"
import {
  AboutImages,
  AboutText,
  AboutWrapper,
  Banner,
  BannerText,
  Gallery,
  HistoryImage,
  HistoryText,
  HistoryWrapper,
  ImageWrapper,
  Info,
  InfoWrapper,
  Value,
  Values,
} from "../styles/styles"
import { number } from "prop-types"

const IndexPage = () => {
  const [slogan, setSlogan] = useState(null)
  const [numbers, setNumbers] = useState(null)
  const [about, setAbout] = useState(null)
  const [historyInfo, setHistoryInfo] = useState(null)
  const [ourValues, setOurValues] = useState(null)
  const [portfolio, setPortfolio] = useState(null)

  useEffect(() => {
    axios.get(`${API_URL}/pages/191`).
      then((res) => {
        setSlogan(res.data.blocks[0].rendered)
        setNumbers(res.data.blocks[1].innerBlocks)
        setAbout(res.data.blocks[2].innerBlocks)
        setHistoryInfo(res.data.blocks[3].innerBlocks)
        setOurValues(res.data.blocks[4].innerBlocks)
        setPortfolio(res.data.blocks[5].innerBlocks)

      })

  }, [])

  const options = {
    caption: {
      showCaption: false,
    },
  }
  return (
    <>
      <Layout lang="ru">
        <SEO title="Главная" lang="ru" />
        <Banner>
          <Container>
            <BannerText>
              {<div
                dangerouslySetInnerHTML={{
                  __html: slogan,
                }}
              />}
            </BannerText>
          </Container>
        </Banner>
        <Container>
          <InfoWrapper>
            {
              numbers?.map(inner => {
                return (
                  <>
                    {inner?.innerBlocks.map(innerBlock => {
                      return (
                        <Info>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: innerBlock.rendered,
                            }}
                          />
                        </Info>
                      )
                    })}
                  </>
                )
              })
            }
          </InfoWrapper>
        </Container>
        <AboutWrapper id="about">
          <AboutText>

            <div
              dangerouslySetInnerHTML={{
                __html: about ? about[0]?.rendered : null
              }}
            />


          </AboutText>
          <AboutImages>
            <div
              dangerouslySetInnerHTML={{
                __html: about ? about[1]?.rendered : null
              }}
            />
          </AboutImages>
        </AboutWrapper>
        <HistoryWrapper id="history">
          <HistoryImage>

            <div
              dangerouslySetInnerHTML={{
                __html: historyInfo ? historyInfo[0]?.rendered : null
              }}
            />

          </HistoryImage>
          <HistoryText>
            <div
              dangerouslySetInnerHTML={{
                __html: historyInfo ? historyInfo[1]?.rendered : null
              }}
            />
          </HistoryText>
        </HistoryWrapper>
        <Container>
          <Values>
            {ourValues?.map((inner, index) => {
              return (
                <Value bordered={index === 1 ? true : false}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: inner.rendered,
                    }}
                  />
                </Value>
              )
            })}
          </Values>
          <Gallery id="portfolio">
            <SimpleReactLightbox>
              <SRLWrapper options={options}>
                {portfolio?.map(inner => {
                  return (
                    <>
                      {inner?.innerBlocks?.map(item => {
                        return (
                          <ImageWrapper>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item?.rendered,
                              }}
                            />
                          </ImageWrapper>
                        )
                      })}
                    </>
                  )
                })}
              </SRLWrapper>
            </SimpleReactLightbox>
          </Gallery>
        </Container>
      </Layout>
    </>
  )
}

export default IndexPage

